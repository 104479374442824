import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import ViewNatalChart from '@/views/ViewNatalChart.vue'
import ViewPlanetaryPositions from '@/views/ViewPlanetaryPositions.vue'
import ViewJamakkol from '@/views/ViewJamakkol.vue'
import ViewPanchanga from '@/views/ViewPanchanga.vue'
import ViewTransits from '@/views/ViewTransits.vue'
import ViewBlogger from '../views/ViewBlogger.vue'
import ViewWidgets from '@/views/ViewWidgets.vue'
import ViewFAQ from '@/views/ViewFAQ.vue'
import ViewContact from '@/views/ViewContact.vue'
import ViewTestCriteriaList from '@/views/ViewTestCriteriaList.vue'
import ViewNatalChartWidget from '@/views/ViewNatalChartWidget.vue'
import ViewNCWSamGeppi from '@/views/ViewNCWSamGeppi.vue'
import ViewNCWJamakkol from '@/views/ViewNCWJamakkol.vue'
import ViewAddWorldCity from '@/views/ViewAddWorldCity.vue'
import ViewRemoveWorldCity from '@/views/ViewRemoveWorldCity.vue'


const routes = [
  {
    path: '/', 
    name: 'home',
    component: Home,
    meta: {
      title: 'Vedic Astrology Chart',
      keywords: 'vedic astrology',
      description: "Create your Vedic astrology natal or birth chart. Explore real-time planetary positions, upcoming transits, Panchanga, and Jamakkol Prasanam, all at your fingertips.",
      link: [
        { 
          rel: 'canonical', 
          href: 'https://www.vedicastrochart.com'
        }
      ]      
    }    
  },
  {
    path: '/natal-chart',
    name: 'natal-chart',
    component: ViewNatalChart,
    meta: {
      title: 'Natal Chart - Vedic Astrology Chart',
      keywords: 'vedic astrology, natal chart, birth chart',
      description: "Unlock the secrets of your cosmic blueprint with our personalized vedic astrology natal chart feature, providing valuable insights into planetary strength, aspects and conjunctions, divisional charts, and Vimsottari Dasa periods.",
      link: [
        { 
          rel: 'canonical', 
          href: 'https://www.vedicastrochart.com/natal-chart'
        }
      ]      
    }    
  },
  {
    path: '/planetary-positions',
    name: 'planetary-positions',
    component: ViewPlanetaryPositions,
    meta: {
      title: 'Planetary Positions - Vedic Astrology Chart',
      keywords: 'vedic astrology, planetary positions',
      description: "Whether you are curious about the planetary alignments at your birth, seeking insight into current transits, or planning auspicious moments for important events, our tool offers precise planetary positions.",
      link: [
        { 
          rel: 'canonical', 
          href: 'https://www.vedicastrochart.com/planetary-positions'
        }
      ]      
    }    
  },
  {
    path: '/transits',
    name: 'transits',
    component: ViewTransits,
    meta: {
      title: 'Transits - Vedic Astrology Chart',
      keywords: 'vedic astrology, planetary transits, transits',
      description: "Track planetary transits over Zodiac signs, Nakshatras (lunar mansions), or specific degree on the ecliptic as it traverses a key point in your chart or explore the broader celestial movements shaping collective energies.",
      link: [
        { 
          rel: 'canonical', 
          href: 'https://www.vedicastrochart.com/transits'
        }
      ]      
    }    
  },
  {
    path: '/panchanga',
    name: 'panchanga',
    component: ViewPanchanga,
    meta: {
      title: 'Panchanga - Vedic Astrology Chart',
      keywords: 'vedic astrology, panchanga, panchangam, vedic calendar, tithi, karana, nakshatra, yoga, yogam',
      description: "Step into the harmonious rhythm of cosmic time with our Panchanga feature, a quintessential Vedic Almanac that unveils the essence of each moment through its five sacred components. Vaara (day of the week), Tithi (lunar phase), Karana (half-day interval), Yoga (auspicious combination of Sun and Moon), and Nakshatra (lunar mansion). Whether you're seeking to plan important events, rituals, or simply align with the natural flow of cosmic energies, our Panchanga offers a comprehensive glimpse into the subtle interplay of cosmic forces.",
      link: [
        { 
          rel: 'canonical', 
          href: 'https://www.vedicastrochart.com/panchanga'
        }
      ]      
    }    
  },
  {
    path: '/jamakkol',
    name: 'jamakkol',
    component: ViewJamakkol,
    meta: {
      title: 'Jamakkol Prasanam - Vedic Astrology Chart',
      keywords: 'jamakkol, jamakol, prasanam, prsannam, prasna, prashna',
      description: "Jamakkol stands as a distinct manifestation within the realm of predictive arts, rooted in the rich tapestry of Vedic astrology. This specialized method, akin to prasanam or horary astrology, possesses a singular focus: to unveil the mysteries of specific queries through the prism of time.",
      link: [
        { 
          rel: 'canonical', 
          href: 'https://www.vedicastrochart.com/jamakkol'
        }
      ]      
    }    
  },
  {
    path: '/blog',
    name: 'blog',
    component: ViewBlogger,
    meta: {
      title: 'Blogs - Vedic Astrology Chart',
      keywords: 'vedic astrology, nakshatra, lunar mansion, vimsottari, vimsottari dasa, south indian chart, north indian chart, hora',
      description: "Whether you're a seasoned practitioner or a curious novice, our blog series offers a wealth of knowledge about the core concepts and timeless wisdom of Vedic astrology.",
      link: [
        { 
          rel: 'canonical', 
          href: 'https://www.vedicastrochart.com/blog'
        }
      ]      
    }    
  },
  {
    path: '/faq',
    name: 'faq',
    component: ViewFAQ,
    meta: {
      title: 'FAQ - Vedic Astrology Chart',
      keywords: 'vedic astrology',
      description: "Welcome to our FAQ page, your go-to resource for clarifying any questions you may have about the myriad features offered on our platform. Whether you're curious about how to generate a personalized vedic astrology natal chart, navigate planetary transits, explore Panchanga, or simply seeking guidance on using our platform effectively, our comprehensive FAQ section provides clear and concise answers to address your inquiries.",
      link: [
        { 
          rel: 'canonical', 
          href: 'https://www.vedicastrochart.com/faq'
        }
      ]      
    }    
  },
  {
    path: '/contact',
    name: 'contact',
    component: ViewContact,
    meta: {
      title: 'Contact Us - Vedic Astrology Chart',
      keywords: 'vedic astrology',
      description: "Need further assistance or have specific inquiries? Don't hesitate to reach out to us via our Contact Us page. Whether you have questions about our astrology tools, need technical support, or seek guidance on interpreting your astrological data, we're here to help.",
      link: [
        { 
          rel: 'canonical', 
          href: 'https://www.vedicastrochart.com/contact'
        }
      ]      
    }    
  },
  {
    path: '/widgets',
    name: 'widgets',
    component: ViewWidgets,
    meta: {
      title: 'Free Natal Chart Widget - Vedic Astrology Chart',
      keywords: 'vedic astrology, natal chart widget, birth chart widget',
      description: "Do you want to embed a natal chart widget in your website or blog? Contact us. We are happy to share a widget link to embed in your website.",
      link: [
        { 
          rel: 'canonical', 
          href: 'https://www.vedicastrochart.com/widgets'
        }
      ]      
    }    
  },
  {
    path: '/natal-chart-widget',
    name: 'ncw-vac',
    component: ViewNatalChartWidget
  },
  {
    path: '/ncw-sam-geppi',
    name: 'ncw-sam-geppi',
    component: ViewNCWSamGeppi
  },
  {
    path: '/ncw-jamakkol',
    name: 'ncw-jamakkol',
    component: ViewNCWJamakkol
  },
  {
    path: '/add-world-city',
    name: 'add-world-city',
    component: ViewAddWorldCity
  },
  {
    path: '/remove-world-city',
    name: 'remove-world-city',
    component: ViewRemoveWorldCity
  },  
  {
    path: '/vactest',
    name: 'vactest',
    component: ViewTestCriteriaList
  },
  {
    path: "/:catchAll(.*)", 
    redirect: '/'
  }  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// ...START: Router Navigation Guard

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else {
    document.title = 'Vedic Astrology Chart | Planetary Positions | Transits | Panchang';
  }

  // If a route with meta keywords is found, 
  // then append to existing meta keywords
  const nearestWithKeywords = to.matched.slice().reverse().find(r => r.meta && r.meta.keywords);  

  if ( nearestWithKeywords ) {
    if ( nearestWithKeywords.meta.keywords ) {
      //console.log('original keywords: ' + getMetaTagContent('keywords'));
      setMetaTagContent('keywords', nearestWithKeywords.meta.keywords);
      //console.log('updated keywords: ' + getMetaTagContent('keywords'));
    }
  }

  // If a route with meta description is found, 
  // then append to existing meta description
  const nearestWithDescription = to.matched.slice().reverse().find(r => r.meta && r.meta.description);  

  if ( nearestWithDescription ) {
    if ( nearestWithDescription.meta.description ) {
      //console.log('original description: ' + getMetaTagContent('description'));
      setMetaTagContent('description', nearestWithDescription.meta.description);
      //console.log('updated description: ' + getMetaTagContent('description'));
    }
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Find the nearest route element with link tags.
  const nearestWithLink = to.matched.slice().reverse().find(r => r.meta && r.meta.link);

  if ( nearestWithLink ) {
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithLink.meta.link.map(tagDef => {
      const tag = document.createElement('link');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  }

  next();

});

// ...END: Router Navigation Guard

export default router

function getMetaTagContent(metaName) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
}

function setMetaTagContent(metaName, metaContent) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].setAttribute('content', metaContent);
    }
  }

  return '';
}

